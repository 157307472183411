import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import LogosDisplay from 'sections/common/LogosDisplay/LogosDisplay'
import {
  Endorsing as EndorsingLogos,
  Investors as InvestorsLogos,
  MediaMentions as MediaMentionsLogos,
} from 'sections/common/LogosDisplay/logosDisplay.utils'

const MediaMentions = ({ backgroundColor }) => {
  const { t } = useTranslation()
  return (
    <LogosDisplay
      logos={MediaMentionsLogos}
      backgroundColor={backgroundColor}
      title={t('commons:mediaMentions:title')}
      subtitle={t('commons:mediaMentions:subTitle')}
      logoLinks={t('commons:mediaMentions:mediaList', {
        returnObjects: true,
      })}
    />
  )
}
MediaMentions.propTypes = {
  backgroundColor: PropTypes.string,
}

const Investors = ({ backgroundColor }) => {
  const { t } = useTranslation()
  return (
    <LogosDisplay
      backgroundColor={backgroundColor || 'neutral.0'}
      logos={InvestorsLogos}
      title={t('commons:investors:title')}
      subtitle={t('commons:investors:subTitle')}
      mobilePaddingBlock="48px"
    />
  )
}
Investors.propTypes = {
  backgroundColor: PropTypes.string,
}

const Endorsing = ({ backgroundColor, mobilePaddingTop }) => {
  const { t } = useTranslation()
  return (
    <LogosDisplay
      backgroundColor={backgroundColor || 'neutral.0'}
      logos={EndorsingLogos}
      title={t('commons:endorsing:title')}
      subtitle={t('commons:endorsing:subtitle')}
      paddingBlock="120px"
      mobilePaddingBlock={`${mobilePaddingTop || '8px'} 72px`}
      titleMargin="8px"
      subtitileMargin="32px"
      center={true}
    />
  )
}
Endorsing.propTypes = {
  backgroundColor: PropTypes.string,
  mobilePaddingTop: PropTypes.string,
}

export { MediaMentions, Investors, Endorsing }
