import React from 'react'
import { Typography } from '@creditas-ui/react'
import PropTypes from 'prop-types'
import { formatQueryParams } from 'utils/formatQueryParams'
import {
  Wrapper,
  Container,
  Subtitle,
  Slider,
  LogoLink,
  LogoContainer,
} from './logosDisplay.styles'

const LogosDisplay = ({
  logos,
  title,
  subtitle,
  logoLinks,
  backgroundColor = 'neutral.10',
  paddingBlock = '96px',
  mobilePaddingBlock = '96px 72px',
  titleMargin = '24px',
  subtitileMargin = '48px',
  subtitleFont = 'bodyXlRegular',
  subtitleMaxWidth = '100%',
  center = false,
} = {}) => (
  <Wrapper backgroundColor={backgroundColor}>
    <Container
      center={center}
      paddingBlock={paddingBlock}
      mobilePaddingBlock={mobilePaddingBlock}
    >
      <Typography
        component="h2"
        variant="headingLgLight"
        color="neutral.90"
        marginBottom={titleMargin}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Subtitle
        color="neutral.80"
        variant={subtitleFont}
        marginBottom={subtitileMargin}
        maxWidth={subtitleMaxWidth}
        marginX="auto"
        dangerouslySetInnerHTML={{
          __html: subtitle,
        }}
      />
      <Slider center={center} alignItems="center">
        {logos.map((item, index) =>
          logoLinks ? (
            <LogoLink
              key={`logo-${item}-${index}`}
              href={formatQueryParams(logoLinks[index].link)}
              rel="noreferrer"
              target="_blank"
              aria-label={logoLinks[index].label}
            >
              {logos[index]()}
            </LogoLink>
          ) : (
            <LogoContainer key={`logo-cont-${item}-${index}`}>
              {logos[index]()}
            </LogoContainer>
          ),
        )}
      </Slider>
    </Container>
  </Wrapper>
)

LogosDisplay.propTypes = {
  logos: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  logoLinks: PropTypes.array,
  backgroundColor: PropTypes.string,
  paddingBlock: PropTypes.string,
  mobilePaddingBlock: PropTypes.string,
  titleMargin: PropTypes.string,
  subtitileMargin: PropTypes.string,
  subtitleFont: PropTypes.string,
  subtitleMaxWidth: PropTypes.string,
  center: PropTypes.bool,
}

export default LogosDisplay
