import {
  Flex,
  media,
  selectTheme,
  styled,
  Typography,
} from '@creditas-ui/react'

export const Wrapper = styled.section`
  background-color: ${({ backgroundColor }) =>
    selectTheme(`colors.${backgroundColor}`)};
  width: 100%;
`

export const Container = styled.div`
  max-width: 1366px;
  margin-inline: auto;
  padding-inline: 20px;
  padding-block: ${({ mobilePaddingBlock }) => mobilePaddingBlock};
  ${media.up('2xl')} {
    text-align: ${({ center }) => (center ? 'center' : 'left')};
  }
  ${media.up('5xl')} {
    padding-inline: 11.5%;
    padding-block: ${({ paddingBlock }) => paddingBlock};
  }
`

export const Subtitle = styled(Typography)`
  a {
    text-decoration: none;
    color: ${selectTheme('colors.primary.90')};
  }
`

export const Slider = styled(Flex)`
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: start;
  ${media.up('2xl')} {
    justify-content: ${({ center }) => (center ? 'center' : 'start')};
  }
`

export const LogoContainer = styled.div`
  margin-right: 48px;
  padding-bottom: 8px;
  :last-child {
    margin-right: 0;
  }
  ${media.between('2xl', '5xl')} {
    margin-right: 40px;
  }
`

export const LogoLink = styled.a`
  margin-right: 48px;
  padding-block: 16px;
  text-decoration: none;
  cursor: pointer;
  :hover {
    path {
      fill: ${selectTheme('colors.neutral.90')};
    }
  }
  :last-child {
    margin-right: 0;
  }
  ${media.between('2xl', '5xl')} {
    margin-right: 40px;
  }
`
